<template>
  <div class="height_100">服务币充值</div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
</style>
